<template>
  <v-bottom-sheet v-model="sheetInstallApp">
    <v-sheet class="text-center" height="480px">
      <div class="d-flex justify-space-between pa-5">
        <p class="title">Instalar aplicativo?</p>
        <v-btn icon @click="sheetInstallApp = false">
          <v-icon>close</v-icon>
        </v-btn>
      </div>
      <div class="d-flex flex-column align-center mb-5">
        Para instalar o aplicativo, basta clicar no botão abaixo "Compartilhar":
        <v-img max-width="200px" :src="shareButtonIos" />
      </div>

      <div class="d-flex flex-column align-center mb-5">
        E depois no botão "Adicionar à tela inicial":
        <v-img max-width="150px" :src="addHomescreenButtonIos" />
      </div>
      <div>
        <v-icon class="black--text">arrow_downward</v-icon>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  computed: {
    shareButtonIos() {
      return require("@/assets/share-ios.png");
    },
    addHomescreenButtonIos() {
      return require("@/assets/homescreen-ios.png");
    },
    sheetInstallApp: {
      get() {
        return this.$store.state.settings.sheetInstallApp;
      },
      set(value) {
        return this.$store.commit("settings/changeSheetInstallApp", value);
      },
    },
  },
};
</script>

<style></style>
